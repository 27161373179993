import Choice from './Choice';
import Slot from './Slot';

export default class Discipline {
    constructor(data) {
        this.id = data.id;
        this.name = data.discipline_name;
        this.description = data.description ? data.description : null;
        this.teacher_name = data.teacher_name ? data.teacher_name : null;
        this.teacher_bio = data.teacher_bio ? data.teacher_bio : null;
        this.slot = data.slot ? new Slot(data.slot) : null;
        this.choice = data.choice ? new Choice(data.choice) : null;
        this.available_choices = data.available_choices ? data.available_choices : [];
    }
}
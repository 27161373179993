import React from 'react';
import './Footer.scss';
import logo from '../../assets/images/logo.png';
import khai from '../../assets/images/KhAI.png';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";

class Footer extends React.Component {
    render() {
        const { proposition, favourite, showDetails, extraClass='', t, lang, status } = this.props;

        return (
            <div className="footer-wrapper outer-wrapper">
                <div className="footer-column support-section">
                    <div className="support">
                        <h3>{t('support.title')}:</h3>
                        {/*<p>{t('support.name')}</p>*/}
                        <a href="/" className="email">{t('support.email')}</a>
                        {/*<a href="/" className="telegram">{t('support.telegram')}</a>*/}
                    </div>
                    {/*<div className="consultant">*/}
                    {/*    <h3>{t('consultant.title')}:</h3>*/}
                    {/*    <p>{t('consultant.name')}</p>*/}
                    {/*    <a href="/" className="email">{t('consultant.email')}</a>*/}
                    {/*</div>*/}
                </div>
                <div className="footer-column logo-section">
                    <a href="/" className="footer-logo">
                        <img src={logo} alt="logo" />
                    </a>
                    <a href="/" className="footer-khai">
                        <img src={khai} alt="KhAI" />
                    </a>
                    <p>{t('copyright.copyright')}</p>
                </div>
                <div className="footer-column team-section">
                    <h3>{t('team.title')}:</h3>
                    <p>{t('team.name1')}</p>
                    <p>{t('team.name2')}</p>
                    <p>{t('team.name3')}</p>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Footer);

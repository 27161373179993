import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './UserPage.scss';
import CourseItem from "../CoursesListingBlock/CourseItem/CourseItem";

class CourseBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { proposition, favourite, showDetails, extraClass='', t, lang, status } = this.props;

        return (
            <div className="user-course course-wrapper">
                <div className="header-section">
                    <h2 className="course-title">{t('course.clusters.title')}</h2>
                    <h3 className="course-selected">{t('course.clusters.course_selected')}</h3>
                    <h3 className="course-selectable">{t('course.clusters.selectable')}</h3>
                    <h3 className="course-description">{t('course.clusters.description')}</h3>
                </div>
                <div className="body-section">
                    <CourseItem/>
                    <CourseItem/>
                    <CourseItem/>
                    <CourseItem/>
                    <CourseItem/>
                </div>
            </div>
        );
    }
}

const mapStateProps = (state) => ({
    lang: state.setMainParams.language,
    status: localStorage.getItem('status')
});

export default withTranslation()(connect(mapStateProps)(CourseBlock));
import React from 'react';
import './Navigation.scss';
import locale from '../../assets/images/locale.svg';
import person from '../../assets/images/person.svg';
import question from '../../assets/images/question.svg';
import search from '../../assets/images/search.svg';
import telegram from '../../assets/images/telegram.svg';
import logout from '../../assets/images/logout.svg';
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";
import { withTranslation } from "react-i18next";
import { BrowserRouter as Router, Link, Redirect, Route, Switch, useParams } from "react-router-dom";
import SearchForm from "../SearchForm/SearchForm";
import Header from "../Header/Header";
import SlotsListingBlock from "../CoursesListingBlock/SlotsListingBlock";
import CourseDetailed from "../CoursesListingBlock/CourseDetailed/CourseDetailed";
import Footer from "../Footer/Footer";
import API from "../../services/API";
import HeaderBanner from "../Header/HeaderBanner";
import { GoogleLogout } from 'react-google-login';

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        const { getStudentSlots, studentUser } = this.props;
        const params = {
            group_num: studentUser.group.group_num,
            student_id: studentUser.id
        }
        API.getSlots(params)
            .then(({ slots, student_choices }) => {
                getStudentSlots(slots, student_choices, params);
            });
    }

    logout(response) {
        console.log(response);
        const { clearUserProfile } = this.props;
        clearUserProfile();
    }

    render() {
        const { t, studentUser, setCurrentDiscipline } = this.props;

        return (
            <Router>
                <div className="nav-wrapper">
                    <div className="icon-wrappers">
                        <ul>
                            <li>
                                <Link to="/user">
                                    <img src={person} alt="person"/>
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to="/discipline">*/}
                            {/*        <img src={telegram} alt="telegram"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to="/faq">*/}
                            {/*        <img src={question} alt="question"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <GoogleLogout
                                    clientId="98045918726-t26pvpf08fb9d7542cgvkfarcd7k4itd.apps.googleusercontent.com"
                                    icon={false}
                                    onLogoutSuccess={this.logout}
                                >
                                    <Link to="/logout">
                                        <img src={logout} alt="question"/>
                                    </Link>
                                </GoogleLogout>
                            </li>
                            {/*<li>
                                <a href="/" className="search-icon" target="_blank">
                                    <img src={search} alt="search"/>
                                </a>
                            </li>*/}
                            {/*<li>
                                <a href="/" className="locale-icon" target="_blank">
                                    <img src={locale} alt="locale"/>
                                </a>
                            </li>*/}
                        </ul>
                    </div>
                </div>

                <Switch>
                    <Route path="/user">
                        <HeaderBanner studentUser={studentUser}/>
                        <div className="page-wrapper outer-wrapper">
                            <div className="description">
                                <h2 className="description-title">
                                    {t('description.home_title')}
                                </h2>
                                <div className="description-body">
                                    {t('description.home_description')}
                                </div>
                            </div>
                        </div>
                        <SlotsListingBlock/>
                        <Footer/>
                    </Route>
                    <Route path="/discipline/:slot_id/:discipline_id">
                        <Header/>
                        <CurrentDiscipline
                            studentUser={studentUser}
                            setCurrentDiscipline={setCurrentDiscipline}/>
                        <CourseDetailed/>
                        <Footer/>
                    </Route>
                    <Route path="/faq">
                        <Header/>
                    </Route>
                    <Route path="/logout">
                        <Redirect to="/user"/>
                    </Route>
                    <Route path="/">
                        <Redirect to="/user"/>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

function CurrentDiscipline({ studentUser, setCurrentDiscipline }) {
    const { slot_id, discipline_id } = useParams();
    API.getDiscipline({ slot_id, discipline_id, student_id: studentUser.id })
        .then(({ slot, currentDiscipline }) => {
            setCurrentDiscipline(currentDiscipline);
        });
    return <div />
}

const mapStateProps = (state) => ({
    studentUser: state.slots.studentUser,
    studentSlots: state.slots.studentSlots,
});

const mapDispatchToProps = (dispatch) => ({
    getStudentSlots: (slots, student_choices, params) => dispatch(actionCreators.getStudentSlots(slots, student_choices, params)),
    clearSlots: () => dispatch(actionCreators.clearStudentSlots()),
    setCurrentDiscipline: (currentDiscipline) => dispatch(actionCreators.setCurrentDiscipline(currentDiscipline)),
    clearUserProfile: () => dispatch(actionCreators.clearUserProfile()),
});

export default withTranslation()(connect(mapStateProps, mapDispatchToProps)(Navigation));

import React, {Suspense} from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import CourseItem from "./CourseItem/CourseItem";
import './CourseListingBlock.scss';

class CourseListingBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { slot, t } = this.props;

        if (slot.disciplines.length) {
            return (
                <div className="course-listing course-wrapper">
                    <div>
                        <h2 className="course-title">{slot.slot.slot_name}</h2>
                    </div>
                    <div className="course-wrapper">
                        {
                            slot.disciplines.map((discipline, index) => (
                                <CourseItem
                                    slot={slot.slot}
                                    discipline={discipline}
                                />
                            ))
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div>

                </div>
            );
        }

    }
}

export default withTranslation()(CourseListingBlock);

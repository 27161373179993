import React from 'react';
import { withTranslation } from "react-i18next";
import './LogIn.scss';
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";

class LogInPassword extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <form className="login-form-wrapper">
                    <input type="email" id="email" name="email" placeholder="example@khai.edu"/>
                    <input type="password" id="password" name="password" placeholder=""/>
                    <div className="button">
                        <a href="/">{t('login.login')}</a>
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(LogInPassword);

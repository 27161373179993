import React, {Suspense} from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import CourseItem from "../CoursesListingBlock/CourseItem/CourseItem";
import './UserPage.scss';

class AllCourses extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { proposition, favourite, showDetails, extraClass='', t, lang, status } = this.props;

        return (
            <div className="all-course course-wrapper">
                <h2 className="title">{t('course.title')}</h2>
                <div className="course-wrapper">
                    <CourseItem/>
                    <CourseItem/>
                    <CourseItem/>
                    <CourseItem/>
                    <CourseItem/>
                    <CourseItem/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AllCourses);

import React from 'react';
import { withTranslation } from "react-i18next";
import './LogIn.scss';
import logo from '../../assets/images/logo.png';
import LogInPassword from "./LogInPassword";
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";
import { GoogleLogin } from 'react-google-login';
import API from "../../services/API";

class LogIn extends React.Component {
    render() {
        const { t, loginUser } = this.props;

        const onSuccessGoogleLogin = (response) => {
            API.logIn(response.profileObj)
                .then(({ googleProfile, studentUser }) => {
                    loginUser(googleProfile, studentUser);
                });
        }

        const onFailureGoogleLogin = (response) => {
            console.log(response);
        }

        return (
            <div className="login-wrapper">
                <div className="logo-wrapper">
                    <a href="/" className="header-logo">
                        <img src={logo} alt="logo" />
                    </a>
                </div>
                <div className="content-section">
                    <p>{t('login.body')}</p>
                    {/*<LogInPassword/>*/}
                    {/*<div className="google-button button">
                        <a href="/">{t('login.google')} ></a>
                    </div>*/}
                    <GoogleLogin
                        clientId="98045918726-t26pvpf08fb9d7542cgvkfarcd7k4itd.apps.googleusercontent.com"
                        buttonText={t('login.google')}
                        onSuccess={onSuccessGoogleLogin}
                        onFailure={onFailureGoogleLogin}
                        isSignedIn={true}
                        cookiePolicy={'single_host_origin'}
                    />
                    {/*<div className="button disactiv">*/}
                    {/*    <a href="/">{t('login.guest')} ></a>*/}
                    {/*</div>*/}
                    <p className="help">{t('login.help')}</p>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loginUser: (googleProfile, studentUser) => dispatch(actionCreators.saveUserProfile(googleProfile, studentUser)),
});

export default withTranslation()(connect(null, mapDispatchToProps)(LogIn));
import React from 'react';
import { withTranslation } from "react-i18next";
import propTypes from 'prop-types';
import { connect } from 'react-redux';

const SearchItem = ({ t }) => (
    <div className="search-item search-row">
      <div className="content-section">
        <div className="body-section">
          <h5>Example title</h5>
          <p>Example summary</p>
        </div>
        <div className="button-section">
          <a className="detailed-button">
            {t('course.course_detailed.detailed')}
          </a>
        </div>
      </div>
    </div>
);

export default withTranslation()(SearchItem);

import React from 'react';
import { withTranslation } from "react-i18next";
import SearchItem from "../Shared/SearchResultItem/SearchItem";
import './SearchForm.scss';
import logo from '../../assets/images/logo.png';
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";

class SearchForm extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div className="search-result">
                <div className="search-result-inner-wrapper">
                    <div className="search-count"><p>We found 19 results</p></div>
                    <div className="search-table">
                        <SearchItem/>
                        <SearchItem/>
                        <SearchItem/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchForm);

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './CourseDetailed.scss';
import API from "../../../services/API";
import * as actionCreators from "../../../store/action";

class ModalWindow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chosen_priority: null
        }

        this.savePriority = this.savePriority.bind(this);
    }

    setPriority(priority) {
        this.setState({ chosen_priority: priority });
    }

    savePriority() {
        const { chosen_priority } = this.state;

        if (chosen_priority) {
            const { studentUser, currentDiscipline } = this.props;

            const params = {
                student_id: studentUser.id,
                discipline_id: currentDiscipline.id,
                priority: chosen_priority,
            };
            API.setPriority(params)
                .then(({ priority }) => {
                    const { getStudentSlots, studentUser } = this.props;
                    const params = {
                        group_num: studentUser.group.group_num,
                        student_id: studentUser.id
                    }
                    API.getSlots(params)
                        .then(({ slots, student_choices }) => {
                            getStudentSlots(slots, student_choices, params);
                        });
                });
        }
    }

    render() {
        const { currentDiscipline, studentUser, t } = this.props;
        const { chosen_priority } = this.state;
        const priorities = [1, 2, 3, 4, 5];
        const prioritiesMarks = [];

        for(let i = 0; i < priorities.length; i++) {
            if (currentDiscipline.available_choices.includes(priorities[i]) && priorities[i] !== chosen_priority) {
                prioritiesMarks[i] = <a onClick={() => (this.setPriority(priorities[i]))}>
                    <div className="default choose-item"><span>{priorities[i]}</span></div></a>;
            } else if (priorities[i] === chosen_priority) {
                prioritiesMarks[i] = <div className="active choose-item"><span>{priorities[i]}</span></div>;
            } else {
                prioritiesMarks[i] = <div className="inactive choose-item"><span>{priorities[i]}</span></div>;
            }
        }

        return (
            <div id="openModal" className="modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a href="#close" title="Close" className="close">×</a>
                        </div>
                        <div className="modal-body">
                            <p className="text-content">{t('course.course_detailed.modal.body_1')}</p>
                            <h2 className="course-title">{currentDiscipline.name}</h2>
                            <p className="text-content">{t('course.course_detailed.modal.body_2')}</p>
                            <div className="choose-pr-wrapper">
                                {
                                    prioritiesMarks.map((priority) => (priority))
                                }
                                {/*<div className="inactive choose-item"><span>2</span></div>*/}
                                {/*<div className="default choose-item"><span>3</span></div>*/}
                                {/*<div className="active choose-item"><span>4</span></div>*/}
                                {/*<div className="default choose-item"><span>5</span></div>*/}
                            </div>
                            {
                                (chosen_priority && studentUser.group.group_num !== 'observer')
                                    ? <div className="button">
                                        <a href="#close" onClick={this.savePriority}>
                                            {t('course.course_detailed.modal.btn_label')}
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateProps = (state) => ({
    studentUser: state.slots.studentUser,
    currentDiscipline: state.slots.currentDiscipline
});

const mapDispatchToProps = (dispatch) => ({
    getStudentSlots: (slots, student_choices, params) => dispatch(actionCreators.getStudentSlots(slots, student_choices, params)),
    setCurrentDiscipline: (currentDiscipline) => dispatch(actionCreators.setCurrentDiscipline(currentDiscipline)),
});

export default withTranslation()(connect(mapStateProps, mapDispatchToProps)(ModalWindow));
import initialState from '../state';
import * as actionType from '../action';

const slots = (state = initialState, action ) => {
  switch (action.type) {
    case actionType.GET_STUDENT_SLOTS:
      return {
        ...state,
        studentSlots: [...action.slots],
        studentChoices: action.student_choices
      };

    case actionType.CLEAR_STUDENT_SLOTS:
      return {
        ...state,
        studentSlots: [],
        studentChoices: null
      };

    case actionType.SAVE_USER_PROFILE:
      return {
        ...state,
        googleProfile: action.googleProfile,
        studentUser: action.studentUser,
      };

    case actionType.CLEAR_USER:
      return {
        ...state,
        googleProfile: null,
        studentUser: null,
      };

    case actionType.SET_CURRENT_DISCIPLINE:
      return {
        ...state,
        currentDiscipline: action.currentDiscipline,
      };

    case actionType.CLEAR_CURRENT_DISCIPLINE:
      return {
        ...state,
        currentDiscipline: null,
      };

    // case actionType.SAVE_USER_CODE:
    //   localStorage.setItem('userCode', action.userCode);
    //   return {
    //     ...state,
    //     userCode: action.userCode,
    //   };

    default: return state;
  }
};

export default slots;
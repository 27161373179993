import React, {Suspense} from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import CourseListingBlock from "./CourseListingBlock";

class SlotsListingBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { slots, t } = this.props;

        return (
            <div>
                {slots.map((slot, index) => (
                    <CourseListingBlock
                        slot={slot}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    slots: state.slots.studentSlots,
    user_code: state.slots.userCode,
});

export default withTranslation()(connect(mapStateToProps)(SlotsListingBlock));
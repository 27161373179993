import initialState from '../state';
import * as actionType from '../action';

const setMainParams = (state = initialState, action) => {
  switch (action.type) {
     default:
      return state;
  }
};

export default setMainParams;
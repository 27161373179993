import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import './i18n';
import { reducer as formReducer } from 'redux-form';
import setMainParams from './store/reducers/setMainParams';
import slots from './store/reducers/slots';
import './styles/index.scss';
import App from './App';

const reducers = { form: formReducer, setMainParams, slots: slots };
const reducer = combineReducers(reducers);
let store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

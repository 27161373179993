import React, {Suspense} from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Navigation from "./components/Navigation/Navigation";
import Header from "./components/Header/Header";
import HeaderBanner from "./components/Header/HeaderBanner";
import CourseListingBlock from "./components/CoursesListingBlock/CourseListingBlock";
import Footer from "./components/Footer/Footer";
import CourseDetailed from "./components/CoursesListingBlock/CourseDetailed/CourseDetailed";
import LogIn from "./components/LogIn/LogIn";
import SearchForm from "./components/SearchForm/SearchForm";
import UserPage from "./components/UserPage/UserPage";
import './styles/App.scss';
import * as actionCreators from './store/action';
import {Link, Redirect, Route, Router, Switch, withRouter} from "react-router-dom";
import person from "./assets/images/person.svg";
import telegram from "./assets/images/telegram.svg";
import question from "./assets/images/question.svg";
import search from "./assets/images/search.svg";

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, googleProfile, studentUser } = this.props;

        return (
            <div className="App">
                {/*<SearchForm/>*/}
                {
                    (googleProfile == null)
                        ? <LogIn/>
                        : (studentUser === null)
                            ? <div><LogIn/><h4>{t('login.not')}</h4></div>
                            : <Navigation/>
                }

                {/*<Router>
                    <Header/>
                    <Switch>
                        <Route path="/user">
                            <Header/>
                            <div className="page-wrapper outer-wrapper">
                                <div className="description">
                                    <h2 className="description-title">
                                        {t('description.home_title')}
                                    </h2>
                                    <div className="description-body">
                                        {t('description.home_description')}
                                    </div>
                                </div>
                            </div>
                            <CourseListingBlock/>
                        </Route>
                        <Route path="/search">
                            <SearchForm/>
                        </Route>
                        <Route path="/faq">
                            <SearchForm/>
                        </Route>
                        <Route path="/">
                            <Redirect to="/user"/>
                        </Route>
                    </Switch>
                </Router>*/}
            </div>
        );
    }
}

const mapStateProps = (state) => ({
    googleProfile: state.slots.googleProfile,
    studentUser: state.slots.studentUser,
});

export default withTranslation()(connect(mapStateProps)(App));

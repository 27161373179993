import axios from 'axios';
import User from '../models/User';
import Slot from "../models/Slot";
import Discipline from "../models/Discipline";

const apiHost = 'https://choice.khai.edu'
//const apiHost = 'http://localhost:5000'

const axiosWithParams = axios.create({
    baseURL: apiHost + '/api/choice/',
    headers: {
        'accept': 'application/json',
    },
});

const axiosWithBody = axios.create({
    baseURL: apiHost + '/api/choice/',
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    },
});

const axiosContacts = axios.create({
    baseURL: apiHost + '/api/choice/',
    headers: {
        'Cache-Control': 'no-cache',
        'Accept': '*/*',
    }
});

axiosWithParams.interceptors.request.use((config) => ({
    ...config,
    params: {
        ...config.params,
    },
}));

axiosWithBody.interceptors.request.use((config) => ({
    ...config,
    body: {
        ...config.data,
    },
}));

axiosContacts.interceptors.request.use((config) => ({
    ...config,
    body: {
        ...config.data,
    },
}));

const API = {
    logIn(params) {
        return axiosWithBody.post('login', JSON.stringify(params))
            .then(({ data }) => {
                return ({
                    /** @var {{user}} data */
                    studentUser: data.user,
                    googleProfile: params
                })
            })
            .catch((error) => console.log(error));
    },

    getSlots(params) {
        return axiosWithBody.post('get_slots', JSON.stringify(params))
            .then(({ data }) => {
                return ({
                    /** @var {{slots, student_choices}} data */
                    student_choices: data.student_choices,
                    slots: Object.keys(data.slots).map((id_slot) => ({
                        slot: new Slot(data.slots[id_slot]),
                        disciplines: Object.keys(data.slots[id_slot].disciplines).map((id_discipline) => ({
                            discipline: new Discipline(data.slots[id_slot].disciplines[id_discipline]),
                        }))
                    }))
                })
            })
            .catch((error) => console.log(error));
    },

    getDiscipline(params) {
        return axiosWithBody.post('get_discipline', JSON.stringify(params))
            .then(({ data }) => {
                return ({
                    /** @var {{discipline}} data */
                    currentDiscipline: new Discipline(data.discipline)
                })
            })
            .catch((error) => console.log(error));
    },

    setPriority(params) {
        return axiosWithBody.post('set_priority', JSON.stringify(params))
            .then(({ data }) => {
                return data
            })
    },

    /*async sendAsync(contacts) {
        const response = await fetch('http://localhost:8080/assistant_requests.php', {
            method: 'post',
            headers: {
                'Cache-Control': 'no-cache',
                'Accept': '*!/!*',
            },
            params: {

            }
        });
    },*/

    /*async sendLook(look) {
        return axiosWithBody.post('look', JSON.stringify(look))
            .then(({ data }) => { return data })
    },*/
};

export default API;
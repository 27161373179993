import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './UserPage.scss';
import AllCourses from "./AllCourses";
import CourseBlock from "./CourseBlock";

class UserPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { proposition, favourite, showDetails, extraClass='', t, lang, status } = this.props;

        return (
            <div className="user-page outer-wrapper">
                <div className="title-section">
                    <h2 className="user-name title">{t('users.name1')}</h2>
                </div>

                <CourseBlock/>

                <div className="button">
                    <a href="/">{t('course.all_courses')}</a>
                </div>

                <AllCourses/>
            </div>
        );
    }
}

const mapStateProps = (state) => ({
    lang: state.setMainParams.language,
    status: localStorage.getItem('status')
});

export default withTranslation()(connect(mapStateProps)(UserPage));
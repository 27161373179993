import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './CourseDetailed.scss';
import lecturer from '../../../assets/images/lecturer.png'

class Lecturer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { currentDiscipline, t } = this.props;

        return (
            <div className="lecturer-wrapper">
                {/*<div className="image-section">*/}
                {/*    <img src={lecturer} />*/}
                {/*</div>*/}
                <div className="body-section">
                    <h4 className="title">{currentDiscipline.teacher_name}</h4>
                    <p className="summary">{currentDiscipline.teacher_bio}</p>
                    {/*<p className="body">{t('lecturer.body')}</p>*/}
                </div>
            </div>
        );
    }
}

export default withTranslation()(Lecturer);
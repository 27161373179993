import React, { Suspense } from 'react';
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import './Header.scss';
import logo from '../../assets/images/logo.png';
import banner_home from '../../assets/images/banner_home_2.jpeg'
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";

class HeaderBanner extends React.Component {
    render() {
        const { t, studentUser } = this.props;

        return (
            <div className="header-wrapper">
                <div className="header-logo-wrapper">
                    <a href="/" className="header-logo">
                        <img src={logo} alt="logo" />
                    </a>
                </div>
                <div className="banner-wrapper">
                    <div className="banner-image row-item">
                        <img src={banner_home} alt="courses khai"/>
                    </div>
                    <div className="banner-title row-item">
                        <h1>{t('banner.title_1')}, {"<"}{studentUser.user.first_name}{">"}!</h1>
                        <h1>{t('banner.title_2')}</h1>
                        <h1>{t('banner.title_3')}</h1>
                        <h1>{t('banner.title_4')}</h1>
                        {/*<div className="google-button button">*/}
                        {/*    <a href="/">{t('login.login')} ></a>*/}
                        {/*</div>*/}
                        <h3>{studentUser.user.last_name}</h3>
                        <h3>{studentUser.user.first_name}</h3>
                        <h3>{studentUser.user.second_name}</h3>
                        <h3>Група {studentUser.group.group_num}</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HeaderBanner);

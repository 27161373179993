import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation';
import translationRU from './locales/ru/translation';
import translationUA from './locales/ua/translation';

const fallbackLng = ['ua'];
const availableLanguages = ['ua', 'ru', 'en'];

const resources = {
    en: {
        translation: translationEN
    },
    ua: {
        translation: translationUA
    },
    //ru to ua
    ru: {
        translation: translationUA
    },
};

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        interpolation: {
            escapeValue: false
        },
        lng: localStorage.getItem('i18nextLng') || 'ua',
        fallbackLng,
        debug: true,
        whitelist: availableLanguages,
    });

export default i18n;

export const GET_STUDENT_SLOTS = 'GET_STUDENT_SLOTS';
export const CLEAR_STUDENT_SLOTS = 'CLEAR_STUDENT_SLOTS';
export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_CURRENT_DISCIPLINE = 'SET_CURRENT_DISCIPLINE';
export const CLEAR_CURRENT_DISCIPLINE = 'CLEAR_CURRENT_DISCIPLINE';

export const getStudentSlots = (slots, student_choices, params) => {
  return ({
    type: GET_STUDENT_SLOTS,
    slots,
    student_choices
  });
};

export const clearStudentSlots = () => {
  return ({
    type: CLEAR_STUDENT_SLOTS,
  });
};

export const saveUserProfile = (googleProfile, studentUser) => ({
  type: SAVE_USER_PROFILE,
  googleProfile,
  studentUser
});

export const clearUserProfile = () => ({
  type: CLEAR_USER,
});

export const setCurrentDiscipline = (currentDiscipline) => {
  return ({
    type: SET_CURRENT_DISCIPLINE,
    currentDiscipline,
  });
};

export const clearCurrentDiscipline = () => {
  return ({
    type: CLEAR_CURRENT_DISCIPLINE,
  });
};
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './CourseItem.scss';
import arrowNext from '../../../assets/images/arrow-next.svg'
import arrowPrev from '../../../assets/images/arrow-prev.svg'
import { Link } from "react-router-dom";
import * as actionCreators from "../../../store/action";

class CourseItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, slot, discipline, studentChoices } = this.props;

        return (
            <div className="course-item">
                <a className="content-link">
                    <div className="content-wrapper">
                        <div className="content-section">
                            <p className="description">
                                {discipline.discipline.name}
                            </p>
                        </div>
                        <div className="button-section">
                            <Link to={"/discipline/" + slot.id + "/" + discipline.discipline.id}
                                  className="detailed-button">
                                {t('course.course_detailed.detailed')}
                            </Link>
                        </div>
                    </div>
                </a>
                <div className="rank-wrapper">
                    {
                        (discipline.discipline.id in studentChoices)
                            ? <span className="course-rank">
                                { studentChoices[discipline.discipline.id] }
                              </span>
                            : ''
                    }
                    <div className="btn-wrapper">
                        <div className="prev-btn"><img src={arrowNext} /></div>
                        <div className="next-btn"><img src={arrowPrev} /></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateProps = (state) => ({
    studentUser: state.slots.studentUser,
    studentChoices: state.slots.studentChoices
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentDiscipline: (id) => dispatch(actionCreators.setCurrentDiscipline(id)),
    clearCurrentDiscipline: () => dispatch(actionCreators.clearCurrentDiscipline()),
});

export default withTranslation()(connect(mapStateProps, mapDispatchToProps)(CourseItem));
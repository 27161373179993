import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './CourseDetailed.scss';
import Lecturer from "./Lecturer";
import ModalWindow from "./ModalWindow";
import { Link } from "react-router-dom";

class CourseDetailed extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, currentDiscipline } = this.props;

        if (currentDiscipline) {
            return (
                <div className="course-detailed outer-wrapper">
                    <div className="title-section">
                        <Link to="/user" className="back">
                            {t('Prev')}
                        </Link>
                        <h2 className="course-title">{currentDiscipline.name}</h2>
                    </div>
                    <div className="body-section">
                        <p>{currentDiscipline.description}</p>
                    </div>

                    <Lecturer currentDiscipline={currentDiscipline}/>
                    {
                        (currentDiscipline.choice.priority === null)
                            ? <div className="button">
                                <a href="#openModal">{t('course.course_detailed.select')}</a>
                            </div>
                            : <div className="active choose-item">
                                <h3>{t('course.course_detailed.priority_set')}
                                    {currentDiscipline.choice.priority}</h3>
                            </div>
                    }
                    <ModalWindow />
                </div>);
        } else {
            return (
                <div className="course-detailed outer-wrapper">

                </div>);
        }

    }
}

const mapStateProps = (state) => ({
    studentUser: state.slots.studentUser,
    currentDiscipline: state.slots.currentDiscipline
});

export default withTranslation()(connect(mapStateProps)(CourseDetailed));
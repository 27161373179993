import React from 'react';
import { withTranslation } from "react-i18next";
import './Header.scss';
import logo from '../../assets/images/logo.png';
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";

class Header extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div className="header-wrapper without-banner">
                <div className="header-logo-wrapper">
                    <a href="/" className="header-logo">
                        <img src={logo} alt="logo" />
                    </a>
                </div>
                {/*<div className="title-section">
                    <h1 className="header-title">{t('header.title')}</h1>
                </div>*/}
            </div>
        );
    }
}

export default withTranslation()(Header);

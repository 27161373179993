import React from 'react';
import { withTranslation } from "react-i18next";
import './SearchForm.scss';
import search from '../../assets/images/search-icon.svg'
import logo from '../../assets/images/logo.png';
import SearchResult from "./SearchResult";
import { connect } from 'react-redux';
import * as actionCreators from "../../store/action";

class SearchForm extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div className="search-wrapper">
                <div className="header-logo-wrapper">
                    <a href="/" className="header-logo">
                        <img src={logo} alt="logo" />
                    </a>
                </div>
                <div className="search-section outer-wrapper">
                    <div className="inner-wrapper">
                        <span><img src={search}/></span>
                        <input className="search-input" type="text" placeholder="Type something you want to find" />
                        <input type="reset" className="reset-button" />
                    </div>

                    <SearchResult/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchForm);
